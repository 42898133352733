import { configureStore, AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import appReducer from "./app/app.reducer";
import authReducer from "./auth/auth.reducer";
import diagnosticsReducer from "./disagnostics/diagnostics.reducer";
import recommendationsReducer from "./recommendations/recommendations.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, thunk: true });
};

export const reducer = {
  app: appReducer,
  auth: authReducer,
  recommendations: recommendationsReducer,
  diagnostics: diagnosticsReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
