import axios from "axios";
import { IDiagnostic } from "store/disagnostics/diagnostics.reducer";

const post = async (values: any): Promise<any> => {
  try {
    const response = await axios.post<IDiagnostic[]>("/results", values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось отправить результаты диагностики");
  }
};

const get = async () => {
  try {
    const response = await axios.get<IDiagnostic[]>("/results");
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить результаты диагностики");
  }
};

export const DiagnosticsAPI = { post, get };
