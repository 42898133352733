import { useEffect } from "react";

import { useTypedSelector } from "store";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "store/auth/auth.selector";

export const AUTH_URL = `${process.env.REACT_APP_SSO_URL}?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT}&response_type=code`;

export const Home = () => {
  const navigate = useNavigate();
  const { is_diag_passed, isLoggedIn } = useTypedSelector(selectAuth);

  useEffect(() => {
    if (isLoggedIn) {
      is_diag_passed ? navigate("/recommendations") : navigate("/diagnostics");
    } else {
      if (process.env.NODE_ENV === "development") {
        navigate("/sso_redirect?code=localhost");
      } else {
        window.location.href = AUTH_URL;
      }
    }
  }, [is_diag_passed, isLoggedIn, navigate]);

  return null;
};
