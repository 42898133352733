import { hollandInterpretations, brunerInterpretations } from "utils/constants";

import { useTypedSelector } from "store";
import {
  selectHighHolland,
  selectHighBruner,
} from "store/disagnostics/diagnostics.selector";

import { Collapse, Typography } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const ScaleText = styled(Typography.Text)``;

export const ResultsInterpretations = () => {
  const holland = useTypedSelector(selectHighHolland);
  const bruner = useTypedSelector(selectHighBruner);

  // отфильтруем интерпретации по Холланду для отображения
  const hollandInts = hollandInterpretations.filter((int) =>
    holland.includes(int.title)
  );

  // отфильтруем интерпретации по Брунеру для отображения
  const brunerInts = brunerInterpretations.filter((int) =>
    bruner.includes(int.title)
  );

  return (
    <Wrapper>
      <Collapse accordion>
        <Collapse.Panel
          header={<Typography.Title level={4}>Тест Холланда</Typography.Title>}
          key="Тест Холланда"
        >
          <Collapse accordion>
            {hollandInts.map((interpretation) => (
              <Collapse.Panel
                header={<b>{interpretation.title}</b>}
                key={interpretation.title}
              >
                <ScaleText>{interpretation.description}</ScaleText>
                <ul style={{ marginTop: "0.5rem" }}>
                  {interpretation.industries.map((industry, index) => (
                    <li key={index}>{industry}</li>
                  ))}
                </ul>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Collapse.Panel>
        {/* ели по Брунеру нет шкал с результатами 10 и выше, selector вернёт пустой массив. скроем результаты в этом случае */}
        {brunerInts.length > 0 && (
          <Collapse.Panel
            header={<Typography.Title level={4}>Тест Брунера</Typography.Title>}
            key="Тест Брунера"
          >
            <Collapse accordion>
              {brunerInts.map((interpretation) => (
                <Collapse.Panel
                  header={<b>{interpretation.title}</b>}
                  key={interpretation.title}
                >
                  <ScaleText>{interpretation.description}</ScaleText>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Collapse.Panel>
        )}
      </Collapse>
    </Wrapper>
  );
};
