import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "utils/constants";
import { Dropdown, Menu, Typography, Button } from "antd";

export const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const HeaderWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 5rem;
  border-bottom: 2px solid #edeef1;
`;

export const ContentWrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Logo = styled.img``;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 0.2rem;

  @media ${device.mobileS} {
    visibility: column-reverse;
  }
  @media ${device.mobileM} {
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const Notifications = styled(Button)`
  span {
    svg {
      font-size: 1.2rem !important;
      color: #000 !important;
    }
  }
`;

export const UserInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
`;

export const AccountDropdown = styled(Dropdown)`
  display: none;

  @media ${device.mobileM} {
    display: flex;
  }
`;

export const DropdownMenu = styled(Menu)`
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075) !important;
`;

export const UserName = styled(Typography.Text)`
  display: none;
  color: #4c4c4c;
  font-size: 1rem;
  line-height: 1rem;

  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.mobileM} {
    display: flex;
  }
`;

export const UserId = styled(Typography.Text)`
  display: flex;
  justify-content: flex-end;
  color: #8a8a8a;
  line-height: 1rem;
`;

export const AccountIcon = styled(Typography.Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  background: #8a9197;
  border-radius: 1.5rem;
`;

export const MainWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  flex: 1;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.mobileM} {
    flex-direction: column;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const SidebarWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2rem;
  padding: 0.5rem 0 !important;
  width: 100%;

  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  z-index: 10;

  a {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  @media ${device.mobileS} {
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    width: 8rem;
    padding: 2rem 0 !important;
  }
`;

export const SidebarButton = styled(Button)<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? "#389afb" : "#dbdbdb")};

  span {
    :first-of-type {
      svg {
        font-size: 2rem;
      }
    }
  }
`;
