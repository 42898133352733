import React from "react";
import { useAuth } from "hooks/use-auth.hooks";

import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spin from "antd/lib/spin";
import styled from "styled-components";

import ProtectedRoute from "./components/ProtectedRoute";

import { SSORedirect } from "pages/SSORedirect";
import { Home } from "pages/Home";
import { NotFound } from "pages/NotFound";
import { Diagnostics } from "pages/diagnostics/Diagnostics";

import "./global.scss";

const SSpin = styled(Spin)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logout = React.lazy(() => import("pages/Logout"));
const Recommendations = React.lazy(() => import("pages/recommendations/Recommendations"));
export const App = () => {
  const { isChecking } = useAuth();

  return (
    <BrowserRouter>
      {!isChecking ? (
        <AnimatePresence>
          <React.Suspense
            fallback={<SSpin spinning size="large" tip="Загружаем данные, пожалуйста подождите!" />}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/sso_redirect" element={<SSORedirect />} />
              <Route path="*" element={<NotFound />} />

              <Route path="/diagnostics" element={<ProtectedRoute children={<Diagnostics />} />} />
              <Route
                path="/recommendations"
                element={<ProtectedRoute children={<Recommendations />} />}
              />
            </Routes>
          </React.Suspense>
        </AnimatePresence>
      ) : (
        <SSpin spinning size="large" tip="Загружаем данные, пожалуйста подождите!" />
      )}
    </BrowserRouter>
  );
};
