import { RootState } from "..";
import { createSelector } from "@reduxjs/toolkit";

export const selectResults = (state: RootState) => state.diagnostics;

export const selectHolland = createSelector(selectResults, (state) => {
  return state.filter((item) => item.diag === "holland");
});

export const selectHighHolland = createSelector(selectHolland, (state) => {
  return state
    .sort((a, b) => b.value - a.value)
    .slice(0, 3)
    .map((result) => result.scale);
});

export const selectBruner = createSelector(selectResults, (state) => {
  return state.filter((item) => item.diag === "bruner");
});

export const selectHighBruner = createSelector(selectBruner, (state) => {
  return state.map((result) => {
    return result.value > 9 && result.scale;
  });
});
