import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LS_NAME_TOKEN } from "../../utils/constants";

export interface IUser {
  id: string;
  leader_id: string;
  is_diag_passed: boolean;
  unti_id: string;
  isLoggedIn: boolean;
  token: string | null;
}

const initialState: IUser = {
  token: localStorage.getItem(LS_NAME_TOKEN),
  isLoggedIn: false,
  is_diag_passed: false,
  id: "",
  leader_id: "",
  unti_id: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(_, action: PayloadAction<IUser>) {
      return { ...action.payload, isLoggedIn: true };
    },
    update(state) {
      return { ...state, is_diag_passed: true };
    },
    logout(
      // state
      _,
      action: PayloadAction<IUser>
    ) {
      return { ...action.payload, isLoggedIn: false };
    },
  },
});

const { actions, reducer } = authSlice;

export const { login, update, logout } = actions;

export default reducer;
