import axios from "axios";

const fetch = async (): Promise<any> => {
  try {
    const response = await axios.get(`/positions/matching`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось загрузить список рекомендаций");
  }
};

export const RecommendationsAPI = { fetch };
