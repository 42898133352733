import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecommendationsAPI } from "api/recommendations.api";

export const fetchRecommendations = createAsyncThunk("recommendations/fetch", async () => {
  try {
    const recommendations = await RecommendationsAPI.fetch();
    setRecommendations(recommendations);
    return recommendations;
  } catch (error) {
    throw new Error("Не удалось загрузить результаты");
  }
});

const initialState = [] as any[];

const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState,
  reducers: {
    setRecommendations(_, action: PayloadAction<any[]>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendations.fulfilled, (_, { payload }) => {
      return payload;
    });
  },
});

const { actions, reducer: recommendationsReducer } = recommendationsSlice;

export const { setRecommendations } = actions;

export default recommendationsReducer;
