import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiagnosticsAPI } from "api/diagnostics.api";

export const getResults = createAsyncThunk("diagnostics/get", async () => {
  try {
    const results = await DiagnosticsAPI.get();
    setDiagnostics(results);
    return results;
  } catch (error) {
    return [];
  }
});

export interface IDiagnostic {
  name: string;
  scale_id: number;
  scale?: string;
  diag: "holland" | "bruner";
  value: number;
}

const initialState: IDiagnostic[] = [];

const diagnosticsSlice = createSlice({
  name: "diagnostics",
  initialState,
  reducers: {
    setDiagnostics(_, action: PayloadAction<IDiagnostic[]>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResults.fulfilled, (_, { payload }) => {
      return payload;
    });
  },
});

const { actions, reducer: diagnosticsReducer } = diagnosticsSlice;

export const { setDiagnostics } = actions;

export default diagnosticsReducer;
