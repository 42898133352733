import { useTypedSelector } from "store";
import { selectAuth } from "store/auth/auth.selector";

import {
  HeaderWrapper,
  UserWrapper,
  UserInner,
  UserName,
  UserId,
  AccountDropdown,
  AccountIcon,
  DropdownMenu,
} from "./styles/layout.styled";
import logo from "assets/unti2035.svg";
import { DownOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/auth/auth.reducer";
import { useTypedDispatch } from "store";

const Dropdown = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const auth = useTypedSelector(selectAuth);

  const handleLogout = () => {
    dispatch(logout({ ...auth, isLoggedIn: false }));
    navigate("/logout");
  };

  const items = [
    // {
    //   label: "Личный кабинет",
    //   key: "account_settings",
    // },
    // {
    //   type: "divider",
    //   key: "divider",
    // },
    {
      label: "Выйти",
      key: "logout",
      onClick: handleLogout,
    },
  ];

  return <DropdownMenu items={items} mode="vertical" />;
};

export const Header = () => {
  const { unti_id } = useTypedSelector(selectAuth);

  return (
    <HeaderWrapper>
      <Image src={logo} preview={false} />
      <UserWrapper>
        <UserInner>
          <UserName>Добрый день</UserName>
          <UserId>{unti_id}</UserId>
        </UserInner>
        {/* <AccountIcon shape="circle" size="large" icon={<UserOutlined />} />
         */}

        <AccountDropdown overlay={<Dropdown />} arrow>
          <AccountIcon>UU</AccountIcon>
        </AccountDropdown>

        <AccountDropdown overlay={<Dropdown />} arrow>
          <DownOutlined style={{ fontSize: "0.8rem", cursor: "pointer" }} />
        </AccountDropdown>
      </UserWrapper>
    </HeaderWrapper>
  );
};
