import React from "react";

import {
  PageWrapper,
  MainWrapper,
  ContentWrapper,
} from "./styles/layout.styled";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  return (
    <PageWrapper>
      <Header />
      <MainWrapper>
        <Sidebar />
        <ContentWrapper
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          {children}
        </ContentWrapper>
      </MainWrapper>
    </PageWrapper>
  );
};
