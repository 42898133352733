import { NavLink } from "react-router-dom";
import { SidebarWrapper, SidebarButton } from "./styles/layout.styled";
import { IdcardOutlined, CompassOutlined } from "@ant-design/icons";

export const Sidebar = () => {
  const activeClassName = "selected-tab";

  return (
    <SidebarWrapper>
      <NavLink
        className={({ isActive }) => (isActive ? activeClassName : undefined)}
        to="/diagnostics"
      >
        <SidebarButton type="link" icon={<IdcardOutlined />}>
          Диагностика
        </SidebarButton>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? activeClassName : undefined)}
        to="/recommendations"
      >
        <SidebarButton type="link" icon={<CompassOutlined />}>
          Рекомендации
        </SidebarButton>
      </NavLink>
    </SidebarWrapper>
  );
};
