import styled from "styled-components";
import { Typography } from "antd";
import { motion } from "framer-motion";
import { device } from "utils/constants";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  background: #f0f2f5;
`;

export const IconMark = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  color: #fff;
  background: #258ffb;
  border-radius: 1rem;
`;

export const Title = styled(Typography.Title)`
  margin: 0.3rem 0 !important;
  font-weight: 400 !important;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 100%;
  margin-bottom: 4px;
  background: #f0f5fe;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled(Typography.Text)`
  font-size: 0.9rem;
`;

export const GraphsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 3rem;
  padding: 3rem;
  background: #fff;

  @media ${device.mobileS}, ${device.mobileM}, ${device.mobileL} {
    gap: 1rem;
    padding: 1rem 2rem;
  }

  @media ${device.laptopL} {
    flex-direction: row;
  }
`;

export const GraphWrapper = styled(motion.div)`
  @media ${device.mobileS}, ${device.mobileM}, ${device.mobileL} {
    gap: 1rem;
  }

  @media ${device.tablet} {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
`;
