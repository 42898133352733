import { useEffect, useState } from "react";
import { configResponsive, useResponsive } from "ahooks";
import { responsiveConfig } from "utils/constants";

import { useTypedSelector } from "store";
import { selectBruner } from "store/disagnostics/diagnostics.selector";

import { Spin } from "antd";
import { Radar } from "@ant-design/plots";
import { GraphWrapper } from "pages/diagnostics/Diagnostics.styled";

configResponsive(responsiveConfig);

export const BrunerGraph = () => {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  // возьмём breakpoint tablet за начало отображения label
  const { tablet } = useResponsive();

  useEffect(() => {
    // записываем значение в local state при изменении размера окна
    setIsLargeScreen(tablet);
  }, [tablet]);

  const variants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const brunerResults = useTypedSelector(selectBruner);

  const config = {
    data: brunerResults.map((scale) => ({ ...scale })),
    xField: "scale",
    yField: "value",
    appendPadding: [0, 10, 0, 10],
    xAxis: {
      tickLine: null,
      // скроем label для мобильных разрешений
      label: isLargeScreen
        ? {
            type: "outer",
          }
        : false,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    point: {
      size: 2,
    },
    area: {},
  };
  return (
    <GraphWrapper initial="initial" animate="in" exit="out" variants={variants}>
      {brunerResults.length > 0 ? <Radar {...config} /> : <Spin />}
    </GraphWrapper>
  );
};
