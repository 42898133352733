import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPI } from "api/auth.api";

import { loading } from "../store/app/app.reducer";
import { login } from "../store/auth/auth.reducer";

export const SSORedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  /**
   * Проверяем код авторизации
   */
  const checkCode = useCallback(
    async (code: string) => {
      try {
        // Включаем загрузку
        dispatch(loading(true));
        // Отправляем запрос на сервер
        const response = await AuthAPI.auth(code);
        // Записываем данные в хранилище
        dispatch(login(response));
        // Записываем токен в браузер
        localStorage.setItem("mytrek-token", response.token!);
        // Перенаправляем
        navigate("/diagnostics");
      } catch (error) {
        navigate("/403");
      } finally {
        dispatch(loading(false));
      }
    },
    [dispatch, navigate]
  );

  /**
   * Проверяем наличие кода в строке урл и запускам проверку на сервере
   * или ругаемся!
   */
  useEffect(() => {
    const code = params.get("code");
    if (code) {
      checkCode(code);
    } else {
      navigate("/403");
    }
  }, [checkCode, params, navigate]);

  return null;
};
