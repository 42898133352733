import axios from "axios";
import { IUser } from "../store/auth/auth.reducer";

const whoami = async (token: string): Promise<IUser> => {
  try {
    const response = await axios.get<IUser>("/users/me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem("sso_token_leader_id");
    throw new Error("Токен пользователя не прошел проверку");
  }
};

const auth = async (code: string): Promise<IUser> => {
  try {
    const response = await axios.get<IUser>(`/sso_redirect?code=${code}`);
    return response.data;
  } catch (error) {
    localStorage.removeItem("mytrek-token");
    throw new Error("Токен пользователя не прошел проверку");
  }
};

export const AuthAPI = { whoami, auth };
