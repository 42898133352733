import axios from "axios";
import { IUser } from "../store/auth/auth.reducer";
import { LS_NAME_TOKEN } from "utils/constants";

const whoami = async (token: string): Promise<IUser> => {
  try {
    const response = await axios.get<IUser>("/users/me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem(LS_NAME_TOKEN);
    throw new Error("Токен пользователя не прошел проверку");
  }
};

const auth = async (code: string): Promise<IUser> => {
  try {
    const response = await axios.get<IUser>(`/sso_redirect?code=${code}`);
    return response.data;
  } catch (error) {
    localStorage.removeItem(LS_NAME_TOKEN);
    throw new Error("Токен пользователя не прошел проверку");
  }
};

const passDiag = async (
  token: string,
  is_diag_passed: boolean
): Promise<IUser> => {
  try {
    const response = await axios.post<IUser>(`/users/me`, {
      is_diag_passed,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem(LS_NAME_TOKEN);
    throw new Error("Токен пользователя не прошел проверку");
  }
};

export const AuthAPI = { whoami, auth, passDiag };
