export const LS_NAME_TOKEN = "mytrek-token";
export const url = "https://kios.diag.2035.university/index.js";
export const uuid = "2cad0d42-a9e6-4c4a-a016-7dd3d21ca81e";

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

export const responsiveConfig = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const hollandInterpretations = [
  {
    title: "Реалистичный",
    description: `У вас высокий балл по реалистичному типу личности.  Эти люди часто хороши в механике или спорте. Подходящие  специальности в обучении для реалистичного типа - это:`,
    industries: [
      "Сельское хозяйство",
      "Медсестра/медбрат",
      "Компьютерные науки",
      "Строительство",
      "Механик / машинист",
      "Инженерное дело",
      "Пищевое производство и туризм",
    ],
  },
  {
    title: "Исследовательский",
    description: `У вас высокий балл по исследовательскому типу личности.  Эти люди любят учиться, анализировать и решать проблемные ситуации. Подходящие  специальности в обучении для исследовательского типа - это:`,
    industries: [
      "Морская биология",
      "Инженерное дело",
      "Химия",
      "Зоология",
      "Медицина / Хирургия",
      "Экономика потребления",
      "Психология",
    ],
  },
  {
    title: "Артистичный",
    description: `У вас высокий балл по артистичному типу личности.  Этим людям нравится работать в неструктурированных ситуациях, где они могут использовать свой творческий потенциал. Хорошие специальности для артистичного типа:`,
    industries: [
      "Косметология",
      "Изобразительное и актерское искусство",
      "Фотография",
      "Радио и телевидение",
      "Дизайн интерьера",
      "Архитектура",
    ],
  },
  {
    title: "Социальный",
    description: `У вас высокий балл по социальному типу личности.  Этим людям нравится работать с другими людьми, нежели с неодушевленными предметами. Хорошие специальности для социального типа:`,
    industries: [
      "Консультации",
      "Медицина",
      "Физиотерапия",
      "Туризм",
      "Реклама",
      "Связи с общественностью",
      "Образование / Педагогика",
    ],
  },
  {
    title: "Предприимчивый",
    description: `У вас высокий балл по предприимчивому типу личности. Этим людям нравится работать с другими, им нравится убеждать других и выступать на публике. Хорошие специальности для этого типа:`,
    industries: [
      "Модный мерчандайзинг",
      "Недвижимость",
      "Маркетинговые продажи",
      "Юриспруденция",
      "Политология",
      "Международная торговля",
      "Банковское дело / Финансы",
    ],
  },
  {
    title: "Конвенциональный",
    description: `У вас высокий балл по конвенциональному типу личности. Эти люди очень внимательны к деталям, организованны и любят работать с данными. Хорошие специальности в обучении для них это:`,
    industries: [
      "Бухгалтерский учет",
      "Отчетность в суде",
      "Страхование",
      "Управление",
      "Банковское дело",
      "Аналитика/Машинное обучение/Обработка данных",
    ],
  },
];

export const brunerInterpretations = [
  {
    title: "Предметное",
    description: `У вас высокий балл по шкале Предметное мышление. Люди с практическим складом ума предпочитают предметное мышление, для которого характерны неразрывная связь с предметом в пространстве и времени, осуществление преобразования информации с помощью предметных действий, последовательное выполнение операций. Существуют физические ограничения на преобразование. Результатом такого типа мышления становится мысль, воплощенная в новой конструкции.`,
  },
  {
    title: "Символическое",
    description: `У вас высокий балл по шкале Символическое мышление. Люди с математическим складом ума отдают предпочтение символическому мышлению, когда происходит преобразование информации с помощью правил вывода (в частности, алгебраических правил или арифметических знаков и операций). Результатом является мысль, выраженная в виде структур и формул, фиксирующих существенные отношения между символами.`,
  },
  {
    title: "Образное",
    description: `У вас высокий балл по шкале Образное мышление. Люди с художественным складом ума предпочитают образный тип мышления. Это отделение от предмета в пространстве и времени, осуществление преобразования информации с помощью действий с образами. Нет физических ограничений на преобразование. Операции могут осуществляться как последовательно, так и одновременно. Результатом служит мысль, воплощенная в новом образе.`,
  },
  {
    title: "Креативность",
    description: `У вас высокий балл по шкале Креативность.  Креативность - творческие способности человека, характеризующиеся готовностью к созданию принципиально новых идей. По мнению П. Торренса, креативность включает в себя повышенную чувствительность к проблемам, к дефициту или противоречивости знаний, действия по определению этих проблем, по поиску их решений на основе выдвижения гипотез, по проверке и изменению гипотез, по формулированию результата решения. Для развития творческого мышления используются обучающие ситуации, которые характеризуются незавершенностью или открытостью для включения новых элементов, поощряется к формулировка множества вопросов.`,
  },
  {
    title: "Знаковое",
    description: `У вас высокий балл по шкале Знаковое мышление. Личности с гуманитарным складом ума предпочитают знаковое мышление. Оно характеризуется преобразованием информации с помощью умозаключений.  Знаки объединяются в более крупные единицы по правилам единой грамматики. Результатом является мысль в форме понятия или высказывания, фиксирующего существенные отношения между обозначаемыми предметами.`,
  },
];
