import React from "react";
import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectAuth } from "store/auth/auth.selector";

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoggedIn } = useSelector(selectAuth);

  // Проверяем авторизацию
  if (!isLoggedIn) return <Navigate to="/" />;

  return children;
};

export default React.memo(ProtectedRoute);
