import React from "react";
import axios from "axios";
import { createRoot } from "react-dom/client";

import "./global.scss";

import { Provider } from "react-redux";
import { store } from "./store";
import { App } from "./App";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

axios.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers!.Authorization = token;
  }
  return config;
});

const container = document.getElementById("root");
if (!container) throw new Error("Нет подходящего контейнера для монтирования");

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
