import { useEffect } from "react";
import { url, uuid } from "utils/constants";

import { useNavigate } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "store";

import { getResults, setDiagnostics } from "store/disagnostics/diagnostics.reducer";
import { DiagnosticsAPI } from "api/diagnostics.api";
import { update } from "store/auth/auth.reducer";
import { selectAuth } from "store/auth/auth.selector";

import { message } from "antd";
import { Layout } from "components/Layout";
import { DiagIframe } from "components/DiagIframe";
import { ResultsGraphs } from "components/ResultsGraphs";

export const Diagnostics = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { unti_id, is_diag_passed } = useTypedSelector(selectAuth);

  // Заканчиваем с активностью и получаем результаты
  const handleFinish = async (scales: any) => {
    const normalizedValues = scales.map((scale: any) => {
      return { value: scale.value, scale_id: scale.id };
    });

    try {
      // отправим результаты диагностики и запишем их в store
      const diagnostics = await DiagnosticsAPI.post({
        results: [...normalizedValues],
      });
      diagnostics && dispatch(setDiagnostics(diagnostics));
      // запишем факт прохождения диагностики в store
      dispatch(update());
      navigate("/diagnostics");
    } catch (error) {
      message.error("Что-то пошло не так...");
    }
  };

  // получим результаты прохождения диагностики с сервера и запишем в store
  useEffect(() => {
    dispatch(getResults());
  }, [dispatch]);

  return (
    <Layout>
      {is_diag_passed ? (
        <ResultsGraphs />
      ) : (
        <DiagIframe uuid={uuid} unti_id={unti_id} url={url} onFinish={handleFinish} />
      )}
    </Layout>
  );
};
