import Spin from "antd/lib/spin";
import { useState, useLayoutEffect, useCallback, useRef } from "react";
import { createPortal } from "react-dom";

import styled from "styled-components";

const Iframe = styled.iframe<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  border: none;
  background-color: transparent;
`;

interface DiagIframeProps {
  url: string;
  uuid: string;
  unti_id: string;
  onFinish: (result?: any) => void;
}

export const DiagIframe = ({ url, uuid, unti_id, onFinish }: DiagIframeProps) => {
  const token = "f048be4e831c75d1c55963b836c44f118a0c2fa2";

  const [height, setHeight] = useState(680);
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const [loading, setLoading] = useState(true);
  const requestRef = useRef(0);

  const mountNode = contentRef?.contentWindow?.document;

  // ресайзинг
  const resize = useCallback(() => {
    if (mountNode?.body) {
      const scrollHeight = mountNode?.body.scrollHeight;

      if (height !== scrollHeight) {
        setHeight(scrollHeight);
      }
    }
    requestRef.current = requestAnimationFrame(resize);
  }, [height, mountNode?.body]);

  // Подключаем скрипт и монтируем его в iframe
  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.addEventListener("load", () => {
      setLoading(false);
    });

    mountNode?.body.appendChild(script);

    return () => {
      if (mountNode) {
        script.removeEventListener("load", () => {});
        mountNode.body.removeChild(script);
      }
    };
  }, [mountNode, url]);

  const handleFinish = useCallback(
    (e: MessageEvent) => {
      const result = typeof e.data === "string" ? JSON.parse(e.data) : e.data;
      if (typeof result === "object" && result.finish) {
        onFinish(result.results.scales);
      }
    },
    [onFinish]
  );

  useLayoutEffect(() => {
    window.addEventListener("message", handleFinish);
    return () => window.removeEventListener("message", handleFinish);
  }, [handleFinish]);

  useLayoutEffect(() => {
    requestRef.current = requestAnimationFrame(resize);
    return () => cancelAnimationFrame(requestRef.current);
  }, [resize]);

  return (
    <Spin spinning={loading}>
      <Iframe
        key={uuid}
        ref={setContentRef}
        title={uuid}
        height={height}
        //sandbox="allow-same-origin allow-scripts allow-forms"
      >
        {mountNode?.body &&
          createPortal(
            <div
              id="webbot"
              className="activity-webbot"
              data-unti-id={unti_id}
              data-activity-uuid={uuid}
              data-user-token={token}
              data-game-test="true"
            />,
            mountNode.body
          )}
      </Iframe>
    </Spin>
  );
};
