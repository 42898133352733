import { Button } from "antd";
import { NavLink } from "react-router-dom";
import {
  Wrapper,
  Summary,
  TextContent,
  IconMark,
  Description,
  Title,
  Subtitle,
  GraphsWrapper,
} from "pages/diagnostics/Diagnostics.styled";
import { HollandGraph } from "./HollandGraph";
import { BrunerGraph } from "./BrunerGraph";
import { ResultsInterpretations } from "./ResultsInterpretations";

export const ResultsGraphs = () => {
  return (
    <Wrapper>
      <Summary>
        <TextContent>
          <IconMark>!</IconMark>
          <Description>
            <Title level={5}>Результаты диагностики:</Title>
            <Subtitle>
              Вы успешно прошли диагностику и можете ознакомиться с результатами
              на графиках и интерпретациями шкал ниже
            </Subtitle>
          </Description>
        </TextContent>
      </Summary>
      <GraphsWrapper>
        <HollandGraph />
        <BrunerGraph />
      </GraphsWrapper>
      <Summary>
        <TextContent>
          <IconMark>!</IconMark>
          <Description>
            <Title level={5}>Рекомендации</Title>
            <Subtitle>
              Перейдите к рекомендациям по результатам диагностики, кликнув по
              кнопке ниже
            </Subtitle>
            <NavLink to="/recommendations">
              <Button style={{ marginTop: "1rem" }} type="primary">
                Перейти
              </Button>
            </NavLink>
          </Description>
        </TextContent>
      </Summary>
      <ResultsInterpretations />
    </Wrapper>
  );
};
